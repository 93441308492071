<template>
	<div class="page">
		<Tables ref="xTable" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showOutput="true" @getList="getList" @clearSearch="clearSearch">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData._keywords" size="small" clearable placeholder="请输入关键词查询">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.category" placeholder="发票类别" clearable size="small" style="width: 120px;">
					<el-option label="纸质发票" :value="1"></el-option>
					<el-option label="电子发票" :value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.type" placeholder="发票类型" clearable size="small">
					<el-option label="普通发票" :value="1"></el-option>
					<el-option label="增值税专用发票" :value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.head_type" placeholder="抬头类型" clearable size="small">
					<el-option label="企业单位" :value="1"></el-option>
					<el-option label="个人" :value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.status" placeholder="开票状态" clearable size="small" style="width: 120px;">
					<el-option label="待开票" :value="0"></el-option>
					<el-option label="已开票" :value="2"></el-option>
					<el-option label="已完成" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.create_time" style="width: 240px;" clearable type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始申请时间" end-placeholder="结束申请时间" size="small">
				</el-date-picker>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" title="申请用户" align="left" min-width="60px">
				<template v-slot="{ row }">
					ID：{{row.fans_id}} <br>
					昵称：{{row.nickname}}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="发票类型" align="left" min-width="10px">
				<template v-slot="{ row }">
					<span v-if="row.category_text !=''">
						{{row.category_text}}<br>
					</span>
					<span v-if="row.type_text !=''">
						{{row.type_text}}<br>
					</span>
					<span v-if="row.head_type_text !=''">
						{{row.head_type_text}}
					</span>
				</template>
			</vxe-table-column>

			<vxe-table-column slot="table-item" title="抬头信息" align="left" min-width="100px">
				<template v-slot="{ row }">
					<span v-if="row.head_name !=''">
						抬头：{{row.head_name}}<br>
					</span>
					<span v-if="row.head_type==1 && row.tax_no !=''">
						税号：{{row.tax_no}}<br>
					</span>
					<span v-if="row.head_type==1 && row.register_adress !=''">
						地址：{{row.register_adress}}<br>
					</span>
					<span v-if="row.head_type==1 && row.register_phone !=''">
						电话：{{row.register_phone}}<br>
					</span>
					<span v-if="row.head_type==1 && row.bank !=''">
						开户行：{{row.bank}}<br>
					</span>
					<span v-if="row.head_type==1 && row.bank_no !=''">
						账号：{{row.bank_no}}
					</span>
				</template>
			</vxe-table-column>

			<vxe-table-column slot="table-item" field="ele_price" title="电费" align="center" min-width="10px" />
			<vxe-table-column slot="table-item" field="service_price" title="服务费" align="center" min-width="10px" />
			<vxe-table-column slot="table-item" field="all_money" title="开票总额" align="center" min-width="10px" />
			<vxe-table-column slot="table-item" title="收票地址" align="left" min-width="100px">
				<template v-slot="{ row }">
					<span v-if="row.category==1 && row.address !=''">
						{{row.address}}<br>
					</span>
					<span v-if="row.category==1 && row.name !=''">
						联系人：{{row.name}}<br>
					</span>
					<span v-if="row.category==1 && row.phone !=''">
						电话：{{row.phone}}
					</span>
					<span v-if="row.category==2">
						{{row.email}}
					</span>
				</template>
			</vxe-table-column>

			<!--<vxe-table-column slot="table-item" field="remarks" title="备注信息" align="center" min-width="10px" />-->
			<vxe-table-column slot="table-item" field="status_text" title="开票状态" align="center" min-width="10px" />

			<vxe-table-column slot="table-item" title="发件信息" align="left" min-width="100px">
				<template v-slot="{ row }">
					<span v-if="row.category==1 && row.express_name !=''">
						{{row.express_name}} <br>{{row.express_no}}
					</span>
					<span v-if="row.category==2 && row.url !='' && row.url.indexOf('.pdf') == -1">
						<el-image style="width: 100px; height: 48px;position: relative;left: -15px" :src="row.url"
							:preview-src-list="viewImgList(row)" fit="contain">
						</el-image>
					</span>
					<span v-if="row.category==2 && row.url !='' && row.url.indexOf('.pdf') != -1">
						<a :href="row.url" target="_blank">查看电子发票</a>
					</span>
				</template>
			</vxe-table-column>

			<vxe-table-column slot="table-item" field="create_time" title="申请时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="操作" align="center" min-width="100px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row)" v-if="$hasAccess('user.invoice/detail')">订单
					</el-button>
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('user.invoice/edit')">开票
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 订单详情 -->
		<el-dialog title="付款订单" :visible.sync="detailDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="1200px">
			<vxe-table border highlight-hover-row resizable align="center" :data="detailDialogFormData">
				<vxe-table-column field="type" title="类型"></vxe-table-column>
				<vxe-table-column field="group_name" title="充电桩/电卡"></vxe-table-column>
				<vxe-table-column field="order_no" title="订单编号"></vxe-table-column>
				<vxe-table-column field="ele_money" title="电费"></vxe-table-column>
				<vxe-table-column field="service" title="服务费"></vxe-table-column>
				<vxe-table-column field="pay_price" title="实付款"></vxe-table-column>
				<vxe-table-column field="create_time" title="付款时间"></vxe-table-column>
			</vxe-table>
		</el-dialog>

		<!-- 处理申请 -->
		<el-dialog title="处理申请" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="800px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form"
				size="mini">
				<el-row>
					<el-col :xs="{span:24,offset:0}" :sm="{span:10,offset:1}">
						<!-- 申请信息-->
						<div class="el-block">
							<div class="row-title">申请信息</div>
							<el-form-item label="发票类别：" v-if="editDialogFormData.category_text !=''">
								{{editDialogFormData.category_text}}
							</el-form-item>
							<el-form-item label="发票类型：" v-if="editDialogFormData.type_text !=''">
								{{editDialogFormData.type_text}}
							</el-form-item>
							<el-form-item label="抬头类型：" v-if="editDialogFormData.head_type_text !=''">
								{{editDialogFormData.head_type_text}}
							</el-form-item>
							<el-form-item label="抬头信息：" v-if="editDialogFormData.head_name !=''">
								{{editDialogFormData.head_name}}
							</el-form-item>
							<el-form-item label="公司税号：" v-if="editDialogFormData.tax_no !=''">
								{{editDialogFormData.tax_no}}
							</el-form-item>
							<el-form-item label="注册地址：" v-if="editDialogFormData.register_adress !=''">
								{{editDialogFormData.register_adress}}
							</el-form-item>
							<el-form-item label="注册电话：" v-if="editDialogFormData.register_phone !=''">
								{{editDialogFormData.register_phone}}
							</el-form-item>
							<el-form-item label="开户行：" v-if="editDialogFormData.bank !=''">{{editDialogFormData.bank}}
							</el-form-item>
							<el-form-item label="账号：" v-if="editDialogFormData.bank_no !=''">
								{{editDialogFormData.bank_no}}
							</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:10,offset:2}">
						<!-- 开票金额 -->
						<div class="el-block">
							<div class="row-title">开票金额</div>
							<el-form-item label="电费：">
								{{editDialogFormData.ele_price}}元（参考税额：{{editDialogFormData.ele_taxes}}）
							</el-form-item>
							<el-form-item label="服务费：">
								{{editDialogFormData.service_price}}元（参考税额：{{editDialogFormData.service_taxes}}）
							</el-form-item>
							<el-form-item label="开票总额：">{{editDialogFormData.all_money}}</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:20,offset:1}">
						<!-- 用户信息 -->
						<div class="el-block">
							<div class="row-title">收件信息</div>
							<el-form-item label="收件地址：" v-if="editDialogFormData.category ==1">
								{{editDialogFormData.address}}
							</el-form-item>
							<el-form-item label="联系人：" v-if="editDialogFormData.category ==1">
								{{editDialogFormData.name}}
							</el-form-item>
							<el-form-item label="联系电话：" v-if="editDialogFormData.category ==1">
								{{editDialogFormData.phone}}
							</el-form-item>
							<el-form-item label="电子邮箱：" v-if="editDialogFormData.category ==2">
								{{editDialogFormData.email}}
							</el-form-item>
						</div>
					</el-col>
				</el-row>
				<el-form-item label="快递公司：" prop="express_name" v-if="editDialogFormData.category ==1">
					<el-input v-model="editDialogFormData.express_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="快递单号：" prop="express_no" v-if="editDialogFormData.category ==1">
					<el-input v-model="editDialogFormData.express_no" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="开票状态：" prop="status">
					<el-radio-group v-model="editDialogFormData.status">
						<el-radio :label="0">待开票</el-radio>
						<el-radio :label="2" v-if="editDialogFormData.category ==1">已开票</el-radio>
						<el-radio :label="1">已完成</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="上传发票：" prop="plan_name" v-if="editDialogFormData.category ==2">
					<upload :fileList="imageList" type="file" @getFileInfo="getFileInfoEdit">
					</upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	import XLSX from "xlsx"
	export default {
		name: 'user-invoice-list',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				isLoading: false,
				tableData: [],
				totalPage: 0,
				imageList: [],
				searchFormData: {},
				addDialogFormData: {},
				rules: {},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
			}
		},
		computed: {
			viewImgList() {
				return function(row) {
					return [row.url]
				}
			}
		},
		mounted() {
			this.$refs.xTable.exportTable = () => {
				let workbook = XLSX.utils.book_new();
				let sheet = XLSX.utils.table_to_sheet(document.getElementsByClassName("vxe-table--header")[0]);
				let data = XLSX.utils.table_to_sheet(document.getElementsByClassName("vxe-table--body")[0]);
				let ln = data["!ref"].split(':');
				let cells = XLSX.utils.decode_cell(ln[1]);
				//console.log(cells);
				let arr = [];
				for(let r=0;r<=cells.r;r++){
					let val = [];
					for(let c=0;c<=cells.c;c++){
						//console.log(r,c,XLSX.utils.encode_cell({r:r, c:c}));
						val.push(data[XLSX.utils.encode_cell({r:r, c:c})]);
					}
					arr.push(val);
				}
				XLSX.utils.sheet_add_aoa(sheet, arr, {origin:-1});
				XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1", true);
				//console.log(workbook);
				XLSX.writeFile(workbook, "out.xlsx");
			}
		},
		methods: {
			getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				this.$api.Invoice.InvoiceIndex(params, this.searchFormData).then(res => {
					this.totalPage = res.total
					this.tableData = res.data
					this.isLoading = false
				}).catch(err => {
					this.isLoading = false
				})
			},
			clearSearch() {
				this.searchFormData = {}
			},
			//重置表单
			resetFormData() {
				this.addDialogFormData = {};
				this.imageList = [];
				this.editDialogFormData = {};
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields();
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields();
				}
			},
			//详情
			detailOne(row) {
				this.detailDialogShow = true;
				const params = {
					token: this.$store.state.user.token,
					invo_id: row.id
				}
				this.$api.Invoice.InvoiceDetail(params).then(res => {
					this.detailDialogFormData = res;
				}).catch(err => {})
			},
			//编辑
			editOne(row) {
				this.resetFormData();
				this.editDialogShow = true;
				this.editDialogFormData = row;
			},
			getFileInfo(fileInfo) {
				if (this.addDialogFormData.attach_id && this.addDialogFormData.attach_id == fileInfo && this
					.addDialogFormData.attach_id.indexOf(",") == "-1") {
					//执行删除
					const params = {
						token: this.$store.state.user.token,
						attach_id: this.addDialogFormData.attach_id
					}
					this.$api.Ad.DeleteImg(params);
					this.addDialogFormData.attach_id = "";
					return;
				}
				this.addDialogFormData.attach_id = fileInfo;
			},
			getFileInfoEdit(fileInfo) { // 附件
				this.editDialogFormData.attach_id = fileInfo;
			},
			closeDialog() { // 关闭弹窗
				this.detailDialogShow = false
				this.editDialogShow = false
			},
			// 编辑保存
			saveEdit() {
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(this.editDialogFormData)
				}
				this.$api.Invoice.InvoiceEdit(params).then(() => {
					this.$notify({
						title: '成功',
						message: '编辑成功',
						type: 'success'
					})
					this.closeDialog()
					this.$refs.xTable.refreshTable()
				});
			}
		}
	}
</script>
<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}

	.avatar {
		width: 24px;
		height: 24px;
		display: block;
		margin: 0 auto;
	}

	.avatarD {
		width: 24px;
		height: 24px;
		display: block;
	}

	.txt-disable {
		color: #ddd;
	}

	.avatar-disable {
		filter: grayscale(100%);
	}
</style>
